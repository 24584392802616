import React from "react";
import { ScreenClassProvider } from "react-grid-system";
import { ThemeProvider } from "../src/context/ThemeContext";
import { BlogFiltersProvider } from "../src/context/BlogFiltersContext";
import { HeaderBarProvider } from "../src/context/HeaderBarContext";
import { LightboxProvider } from "../src/context/LighboxContext";
import { LocalToastProvider } from "react-local-toast";

import 'react-local-toast/dist/bundle.css';
import 'react-local-toast/dist/bundle.min.css';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <LocalToastProvider>
        <LightboxProvider>
          <ScreenClassProvider>
            <HeaderBarProvider>
              <BlogFiltersProvider>{element}</BlogFiltersProvider>
            </HeaderBarProvider>
          </ScreenClassProvider>
        </LightboxProvider>
      </LocalToastProvider>
    </ThemeProvider>
  );
};
