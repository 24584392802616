import React, { useState } from "react";

const defaultState = {
  opened: false,
  toggleOpened: () => {},
  setOpened: () => {}
};

const HeaderBarContext = React.createContext(defaultState);

/* eslint-disable */
function HeaderBarProvider({ children }) {
  const [opened, setOpened] = useState(false);

  const toggleOpened = () => {
    const openedToggled = !opened;
    setOpened(openedToggled);
  };

  return (
    <HeaderBarContext.Provider
      value={{
        opened,
        toggleOpened,
        setOpened
      }}
    >
      {children}
    </HeaderBarContext.Provider>
  );
}

export default HeaderBarContext;
export { HeaderBarProvider };
