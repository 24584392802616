import React, { useState } from "react";

const defaultState = {
  openLightbox: false,
  videoUrl: "",
  showVideo: () => {},
  setOpenLightbox: () => {}
};

const LighboxContext = React.createContext(defaultState);

function LightboxProvider({ children }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const showVideo = (video) => {
    setOpenLightbox(true);
    setVideoUrl(video);
  };

  return (
    <LighboxContext.Provider
      value={{
        openLightbox,
        showVideo,
        videoUrl,
        setOpenLightbox
      }}
    >
      {children}
    </LighboxContext.Provider>
  );
}

export default LighboxContext;
export { LightboxProvider };
