import React, { useState } from "react";

const defaultState = {
  dark: false,
  toggleDark: () => {},
};

const ThemeContext = React.createContext(defaultState);

function ThemeProvider({ children }) {
  const [dark, setDark] = useState(false);

  const toggleDark = () => {
    const darkToggled = !dark;
    setDark(darkToggled);
  };

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
export { ThemeProvider };
