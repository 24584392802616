/* eslint-disable */
import React from "react";

const defaultState = {
  getCategories: () => {},
  getTags: () => {},
  setCategories: () => {},
  setTags: () => {},
};

const BlogFiltersContext = React.createContext(defaultState);

function BlogFiltersProvider({ children }) {
  const windowGlobal = typeof window !== "undefined" && window;

  const getCategories = () => {
    if (!windowGlobal) {
      return;
    }
    return JSON.parse(windowGlobal.localStorage.getItem("categories"));
  };

  const getTags = () => {
    if (!windowGlobal) {
      return;
    }
    return JSON.parse(windowGlobal.localStorage.getItem("tags"));
  };

  const setCategories = (newCategories) => {
    if (!windowGlobal) {
      return;
    }
    windowGlobal.localStorage.setItem(
      "categories",
      JSON.stringify(newCategories)
    );
  };

  const setTags = (newTags) => {
    if (!windowGlobal) {
      return;
    }
    windowGlobal.localStorage.setItem("tags", JSON.stringify(newTags));
  };

  return (
    <BlogFiltersContext.Provider
      value={{
        getCategories,
        getTags,
        setCategories,
        setTags,
      }}
    >
      {children}
    </BlogFiltersContext.Provider>
  );
}

export default BlogFiltersContext;
export { BlogFiltersProvider };
